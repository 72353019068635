import { Fragment } from 'react';
import Home from './components/layouts/Home';
import Footer from './components/layouts/Footer';
import Header from './components/layouts/Header';
import Details from './components/layouts/Details';
import ServiceDetails from './components/layouts/ServiceDetails';
import MedicineDetails from './components/layouts/MedicineDetails';
import Medicine from './components/layouts/Medicine';
/* import Login from './components/admin/Login'; */

import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';

import {Provider} from 'react-redux';
import store from './store';


const Viewer = ({match}) => (
    <Fragment>
            <Header/>
            <Switch>
                <Route exact path={`${match.path}`} component={Home} />
                <Route exact path='/news-details/:id' component={Details} />
                <Route exact path='/service-details/:id' component={ServiceDetails} />
                <Route exact path='/medicine' component={Medicine} />
                <Route exact path='/medicine-details/:id' component={MedicineDetails} />
            </Switch>
            <Footer/>
           {/*  <Route exact path={`${match.path}`} component={Dragon}/>
             <Nav/>
                <Switch>
                    <Route exact path={`${match.path}/home`} component={Login} />
                    <Route exact path='/about-us' component={AboutTeam} />
                    <Route exact path='/news' component={News} />
                    <Route exact path='/ask' component={Ask} /> 
                    <Route exact path='/schedule' component={Schedule} /> 
                    <Route exact path='/gallery' component={Gallery} />
                </Switch>
            <Footer/>  */}
    </Fragment>
);

const Admin = ({match}) => (
    <Fragment>
           <Switch>
              {/*   <Route exact path='/home' component={Dragon} /> 
                <Route exact path={`${match.path}`} component={Login} />
                <Route exact path={`${match.path}/home2`} component={Dragon} />
                <Route exact path={`${match.path}/about-us`} component={Login} />
                <Route exact path={`${match.path}/about-us`} component={Login} /> */}
            </Switch>
    </Fragment>
);



const App = () => (
    <Provider store={store} >
        <Router>
           <Switch>
               <Route  path='/admin' component={Admin} /> 
               <Route  path='/' component={Viewer} />
           </Switch>
        </Router>
    </Provider>
);


export default App;

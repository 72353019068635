import React from 'react';
import {AlertContext} from './controlAlert';
import moment from "moment";

class Alert extends React.Component {

    cancelApp = () => {

    }

    hidePopup = () => {

    }

    render() {
       /*  if (this.props.appState.errorData && this.props.appState.errorData.errorHandlingAction == ErrorDataHandlingAction.ShowPopup){ */
            return(
                <AlertContext.Consumer> 
                    { state => {
                        console.log(state);
                        if(state.showAlert === 1) {
                            return (
                                
                                <div id="myModal" className="modal">
                                    <div className="modal-content">
                                    <div>
                                        <p>Bạn đã có lịch đặt vào thời gian:  </p>
                                        <p className='alert_font'>  {state.appoint_time}  </p>
                                    </div>
                                    <div>
                                        <button type="button" className="button no" onClick={state.deleteAppointment}>Hủy lịch</button>
                                        <button type="button" className="button yes" onClick={state.toggleAlert}>Thoát</button>
                                    </div>
                                    </div>
                                </div>
                            
                            );}
                        else if(state.showAlert === 2) { 
                            return (
                            
                                <div id="myModal" className="modal">
                                    <div className="modal-content">
                                    <div>
                                        <p>Bạn đã hủy lịch thành công  </p>
                                    </div>
                                    <div>
                                        <button type="button" className="button yes" onClick={state.toggleAlert}>Thoát</button>
                                    </div>
                                    </div>
                                </div>
                            );
                        }
                        else if(state.showAlert === 3) { 
                            return (
                            
                                <div id="myModal" className="modal">
                                    <div className="modal-content">
                                    <div>
                                        <p> Bạn chưa có lịch đặt nào </p>
                                    </div>
                                    <div>
                                        <button type="button" className="button yes" onClick={state.toggleAlert}>Thoát</button>
                                    </div>
                                    </div>
                                </div>
                            );
                        }
                        else if(state.showAlert === 4) { 
                            return (
                            
                                <div id="myModal" className="modal">
                                    <div className="modal-content">
                                    <div>
                                        <p className="success-title"> ĐẶT LỊCH THÀNH CÔNG </p>
                                        <p> Tên khách hàng: <b className="success-content">{state.app_name}</b> </p>
                                        <p> Đặt lịch vào: <b className="success-content">{ state.app_date }</b> </p>
                                        <p> Giờ khám: <b className="success-content">{state.app_time} </b> </p>
                                        <p> *Chú ý: Nếu có mặt muộn sau giờ trên, lịch sẽ bị hủy, hãy vào báo lại tên và ngồi đợi để khám theo diện không đặt lịch </p>
                                    </div>
                                    <div>
                                        <button type="button" className="button yes" onClick={state.toggleAlert}>Đồng ý</button>
                                    </div>
                                    </div>
                                </div>
                            );
                        }
                        /* alert('Đặt lịch thành công. \n' + 
                        'Tên khách hàng: '+ this.state.k1_name +
                        '\nĐặt lịch vào: '+ moment(this.state.timeslotSelectedDate).format('DD - MM - yyyy') +
                        '\nGiờ khám: '+ this.state.timeslotSelectedText +
                        '\nXin cám ơn quý khách'); */
                        else {
                            return null;
                        }
                }}
                </AlertContext.Consumer>
            )
       // }
        return null;
    }
}
export default Alert;
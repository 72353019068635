import React from 'react';
import {ImageText, DataRPC} from '../../rpc-client/DataRPC';

class Footer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            footer: "",
            mail: "",
            sdt: "",
            footer_image: ""
        }
    }

    async componentDidMount() {
        new DataRPC().fetchData('GetImageText', {
            page: "footer"
        }, ImageText).then(gData => { 
            if (gData) {
                this.setState({footer: gData.find(x => x.komoku === "footer_mieuta").content});
                this.setState({mail: gData.find(x => x.komoku === "footer_mail").content});
                this.setState({sdt: gData.find(x => x.komoku === "footer_sdt").content});

                this.setState({footer_image: gData.find(x => x.komoku === "footer_image").content});
            }
        });
    }

    render() {
        return(
            <footer className="footer section  gray-bg">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 mr-auto col-sm-6">
                            <div className="widget mb-5 mb-lg-0">
                                <div className="logo mb-4">
                                    <img src={this.state.footer_image} alt="" className="img-fluid img-logo-footer" />
                                </div>
                                <p> {this.state.footer} </p>

                          {/*       <ul className="list-inline footer-socials mt-4">
                                    <li className="list-inline-item"><a href="https://www.facebook.com/themefisher"><i className="icofont-facebook"></i></a></li>
                                    <li className="list-inline-item"><a href="https://twitter.com/themefisher"><i className="icofont-twitter"></i></a></li>
                                    <li className="list-inline-item"><a href="https://www.pinterest.com/themefisher/"><i className="icofont-linkedin"></i></a></li>
                                </ul> */}
                            </div>
                        </div>
{/* 
                        <div className="col-lg-2 col-md-6 col-sm-6">
                            <div className="widget mb-5 mb-lg-0">
                                <h4 className="text-capitalize mb-3">Department</h4>
                                <div className="divider mb-4"></div>

                                <ul className="list-unstyled footer-menu lh-35">
                                    <li><a href="#">Surgery </a></li>
                                    <li><a href="#">Wome's Health</a></li>
                                    <li><a href="#">Radiology</a></li>
                                    <li><a href="#">Cardioc</a></li>
                                    <li><a href="#">Medicine</a></li>
                                </ul>
                            </div>
                        </div>

                        <div className="col-lg-2 col-md-6 col-sm-6">
                            <div className="widget mb-5 mb-lg-0">
                                <h4 className="text-capitalize mb-3">Support</h4>
                                <div className="divider mb-4"></div>

                                <ul className="list-unstyled footer-menu lh-35">
                                    <li><a href="#">Terms & Conditions</a></li>
                                    <li><a href="#">Privacy Policy</a></li>
                                    <li><a href="#">Company Support </a></li>
                                    <li><a href="#">FAQuestions</a></li>
                                    <li><a href="#">Company Licence</a></li>
                                </ul>
                            </div>
                        </div> */}

                        <div className="col-lg-3 col-md-6 col-sm-6">
                            <div className="widget widget-contact mb-5 mb-lg-0">
                                <h4 className="text-capitalize mb-3">Liên hệ</h4>
                                <div className="divider mb-4"></div>

                                <div className="footer-contact-block mb-4">
                                    <div className="icon d-flex align-items-center">
                                        <i className="icofont-email mr-3"></i>
                                        <span className="h6 mb-0">Email</span>
                                    </div>
                                    <h4 className="mt-2"><a href="">{this.state.mail}</a></h4>
                                </div>

                                <div className="footer-contact-block">
                                    <div className="icon d-flex align-items-center">
                                        <i className="icofont-support mr-3"></i>
                                        <span className="h6 mb-0">Điện thoại</span>
                                    </div>
                                    <h4 className="mt-2"><a href={"tel:"+this.state.sdt}>{this.state.sdt}</a></h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div className="footer-btm py-4 mt-5">
                        <div className="row align-items-center justify-content-between">
                            <div className="col-lg-6">
                                <div className="copyright">
                                    &copy; Developed <span className="text-color"></span> by <a href="https://www.facebook.com/julanove/" target="_blank">Juchan</a>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-lg-4">
                                <a className="backtop js-scroll-trigger" href="#top">
                                    <i className="icofont-long-arrow-up"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        );
    }
}

export default Footer;
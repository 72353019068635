import React, { Component } from 'react';
import { Fragment } from 'react';
import {ImageText, DataRPC, MedicineClass} from '../../rpc-client/DataRPC';

class Medicine extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            mieuta: "",
            news: []
        }
    }

    async componentDidMount() {
        new DataRPC().fetchData('GetImageText', {
            page: "medicine"
        }, ImageText).then(gData => { 
            if (gData) {
                this.setState({mieuta: gData.find(x => x.komoku == "mieuta").content});
            }
        });

        // Get Medicine
        new DataRPC().fetchData('GetMedicine', {
            type: 2
        }, MedicineClass).then(gData => { 
            //console.log('News ---');
            
            if (gData) {
               this.setState({
                    news: gData
               });
               //console.log(this.state.news);
            }
        });
    }

    allNews = () => {

        let list = [];
            this.state.news.forEach(xxx => {
            
            list.push(
                <div className="col-lg-4 col-md-6 ">
                    <div className="department-block mb-5 gray_border">
                        <img src={xxx.ava} alt="" className="img-fluid w-100"/>
                        <div className="content">
                            <h4 className="mt-4 mb-2 title-color">{xxx.name}</h4>
                            <p className="mb-4">{xxx.description}</p>
                            <a href={"medicine-details/"+xxx.id} className="read-more"> Xem thêm  <i className="icofont-simple-right ml-2"></i></a>
                        </div>
                    </div>
                </div>
            );
        });
    
        return list;
    };

    render() {
        return( 
            <Fragment>

                <section className="section service-2 gray-bg">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-7 text-center">
                                <div className="section-title">
                                    <h2>Danh sách</h2>
                                    <div className="divider mx-auto my-4"></div>
                                    <p>{this.state.mieuta}</p>
                                </div>
                            </div>
                        </div>

                        

                        <div className="row">
                            {this.allNews()}
                        </div>
                    </div>

                    <div></div>
                </section>
            </Fragment>
        )
    }

}

export default Medicine;
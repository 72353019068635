import React from 'react';
import { Fragment } from 'react';
import {ImageText, DataRPC, Services, News, Feedback, TimeSlot, User} from '../../rpc-client/DataRPC';
import moment from "moment";
import Alert from './Alert';
import {AlertContext} from './controlAlert';


class Home extends React.Component {

    constructor(props) {
        super(props);

        this.toggleAlert = () => {
            this.setState({
                showAlert: 0,
            });
        };

        

        this.deleteAppointment = () => {
            new DataRPC().fetchRawData('DeleteAppointment', {
                id: this.state.appoint_id,
                time_id: this.state.time_id
            }).then(gData => { 
                console.log(gData);
                if (gData.success == true) {
                   this.setState({
                        showAlert: 2
                   });
                }
                else {
                   alert('Có lỗi xảy ra, xin thử lại sau');
                }
            }); 
        };

        this.state = {

            k1_type: "text",

            section1_desc: "",

            // Nhóm dịch vụ 1
            button1_desc: "",
            button1_name: "",
            button1_button: "",

            // Nhóm dịch vụ 2
            button2_desc: "",
            button2_name: "",
            button2_button: "",

            // Nhóm dịch vụ 3
            button3_desc: "",
            button3_name: "",
            button3_button: "",

            service: "",
            ykien: "",
            xemlai: "",
            sitename: "",
            tintuc: "",

            // Đăng kí khám
            k1_date: moment().format('yyyy-MM-DD'),
            //k1_date: null,
            k1_time: "10h30",
            k1_name: "",
            k1_phone: "",
            k1_bod: "",
            k1_address: "",
            k1_note: "",

            // Check giờ khám
            k3_phone: "",

            services: [],
            news: [],
            feedback: [],
            timeSlot: [],
            imgXemlai: "",
            imgKham: "",
            imgSieuAm: "",
            imgBanner: "",
            timeslotSelected: "",
            timeslotSelectedText: "",
            timeslotSelectedDate: moment(),

            // Alert's state
            showAlert: 0, // Alert state
            toggleAlert: this.toggleAlert, // Set Alert function
            appoint_time: "",
            appoint_id: "",
            time_id: "",
            deleteAppointment: this.deleteAppointment,

            // Alert's success state
            app_name: "",
            app_date: "",
            app_time: ""

        }

        
    }

    onChangeTimeSlot = (e) => {
        var index = e.nativeEvent.target.selectedIndex;
        this.setState({
            timeslotSelected: e.target.value,
            timeslotSelectedText: e.nativeEvent.target[index].text
        });
    }

    delay = ms => new Promise(
        resolve => setTimeout(resolve, ms)
      );

    handleFocus = async(e) => {
        this.setState({k1_type: "date"})
        await this.delay(300);
        document.getElementById("date").showPicker();
    };

    handleBlur = (e) => {
        if(!e.target.value)
        {
            this.setState({k1_type: "text"})
        }
        
    };

    onChangeDate = (e) => {
        this.setState({[e.target.name]:e.target.value});
        this.setState({
            timeslotSelected: -1,
            timeslotSelectedDate: e.target.value
        });

        new DataRPC().fetchData('GetAppointment', {
            value: moment(e.target.value).format('yyyy-MM-DD'),
            type: 2
        }, TimeSlot).then(gData => { 
            //console.log('TimeSlot --- changed');
            
            if (gData) {
               this.setState({
                timeSlot: gData
               });
               //console.log(this.state.timeSlot);
            }
        });
    } 

    onChange = (e) => {
        this.setState({[e.target.name]:e.target.value});
    } 

    oneChangePhone = (e) => {
        //console.log(e.target.value);
        new DataRPC().fetchData('GetUser', {
            phone: e.target.value
        }, User).then(gData => { 
            if (gData != -1) {
                //console.log(gData);
                this.setState({
                k1_address: gData.address,
                k1_bod: gData.bod,
                k1_name: gData.name
               });
            }
        });
    }

    handleSubmitAgain = async (e) => {
        e.preventDefault();

     /*    if (!this.state.timeslotSelectedText) {
            alert('Bạn chưa chọn giờ khám');
            return;
        } */

        if (!this.state.k3_phone) {
            alert('Bạn chưa nhập số điện thoại');
            return;
        }
        
        new DataRPC().fetchRawData('CheckAppointment', {
            phone: this.state.k3_phone
        }).then(gData => { 
            console.log(gData);
            if (gData.app_id) {
                this.setState({
                    appoint_time: moment(gData.time_from).format('DD/MM/YYYY HH:mm'),
                    appoint_id: gData.app_id,
                    time_id:  gData.time_id,
                    showAlert: 1
                });
            }
            else {
                this.setState({
                    appoint_time: "",
                    appoint_id: "",
                    time_id: "",
                    showAlert: 3
               });
            }
        }); 

        

        

    }
    

    handleSubmit = async (e) => {
        e.preventDefault();

        if (this.state.k1_date < moment().format('YYYY-MM-DD')) {
            alert('Thời gian khám phải lớn hơn ngày hiện tại');
            return;
        }

        if (this.state.timeslotSelected == -1 ||
            !this.state.k1_phone ||
            !this.state.k1_name ||
            !this.state.k1_bod ||
            !this.state.k1_address) {
            alert('Bạn chưa điền đầy đủ thông tin khám');
            return;
        }

        //return
        let result = await new DataRPC().fetchRawData('Appointment', {
            timeslot: this.state.timeslotSelected,
            type: 0,
            phone: this.state.k1_phone,
            name: this.state.k1_name,
            address: this.state.k1_address,
            bod: this.state.k1_bod,
            note: this.state.k1_note
        }).then(gData => {  
            ////console.log(gData);
            if (gData.success == true) {
                 /* alert('Đặt lịch thành công. \n' + 
                        'Tên khách hàng: '+ this.state.k1_name +
                        '\nĐặt lịch vào: '+ moment(this.state.timeslotSelectedDate).format('DD - MM - yyyy') +
                        '\nGiờ khám: '+ this.state.timeslotSelectedText +
                        '\nXin cám ơn quý khách');  */
                    this.setState({
                        showAlert: 4,
                        app_date: moment(this.state.timeslotSelectedDate).format('DD - MM - yyyy'),
                        app_time: this.state.timeslotSelectedText,
                        app_name: this.state.k1_name
                    });

                new DataRPC().fetchData('GetAppointment', {
                    value: moment(this.state.timeslotSelectedDate).format('yyyy-MM-DD'),
                    type: 2
                }, TimeSlot).then(eData => { 
                    ////console.log('TimeSlot --- changed');
                    if (eData) {
                       this.setState({
                        timeSlot: eData
                       });
                       ////console.log(this.state.timeSlot);

                       this.setState({
                        k1_phone: "", 
                        k1_name: "", 
                        k1_address: "",
                        k1_bod: "",
                        k1_note: "",
                        timeslotSelected: "-1"
                    });
                    }
                });
            } 
            else {
                alert(gData.message);
            }
        });

        

        //this.props.setAlert(a,b);
    }

    async componentDidMount() {

        new DataRPC().fetchData('GetImageText', {
            page: "index"
        }, ImageText).then(gData => { 
            if (gData) {

                this.setState({section1_desc: gData.find(x => x.komoku === "section1_desc").content});
               
                this.setState({button1_desc: gData.find(x => x.komoku === "button1_desc").content});
                this.setState({button1_name: gData.find(x => x.komoku === "button1_name").content});
                this.setState({button1_button: gData.find(x => x.komoku === "button1_button").content});

                
                this.setState({button2_desc: gData.find(x => x.komoku === "button2_desc").content});
                this.setState({button2_name: gData.find(x => x.komoku === "button2_name").content});
                this.setState({button2_button: gData.find(x => x.komoku === "button2_button").content});


                this.setState({button3_desc: gData.find(x => x.komoku === "button3_desc").content}); 
                this.setState({button3_name: gData.find(x => x.komoku === "button3_name").content});
                this.setState({button3_button: gData.find(x => x.komoku === "button3_button").content});

                this.setState({service: gData.find(x => x.komoku === "service").content}); 
                this.setState({ykien: gData.find(x => x.komoku === "section_ykien").content}); 
                this.setState({xemlai: gData.find(x => x.komoku === "section_xemlai").content}); 
                this.setState({tintuc: gData.find(x => x.komoku === "section_tintuc").content});

                this.setState({imgKham: gData.find(x => x.komoku === "img_lichkham").content});
                this.setState({imgSieuAm: gData.find(x => x.komoku === "img_lichsieuam").content});
                this.setState({imgXemlai: gData.find(x => x.komoku === "img_xemlai").content});
                this.setState({imgBanner: gData.find(x => x.komoku === "Banner").content});
                this.setState({sitename: gData.find(x => x.komoku === "site_name").content});
            }
        });

        // Get Services
        new DataRPC().fetchData('GetServices', {
        }, Services).then(gData => { 
            ////console.log('Serice ---');
            
            if (gData) {
               this.setState({
                services: gData
               });
            }
        });

        // Get News
        new DataRPC().fetchData('GetNews', {
            type: 2
        }, News).then(gData => { 
            //console.log('News ---');
            
            if (gData) {
               this.setState({
                news: gData
               });
               //console.log(this.state.news);
            }
        });

        // Get Feedback
        new DataRPC().fetchData('GetFeedback', {
            type: 0
        }, Feedback).then(gData => { 
            //console.log('Feedback ---');
            
            if (gData) {
               this.setState({
                feedback: gData
               });
               //console.log(this.state.feedback);
            }
        });

        // Get Thời gian khám

        new DataRPC().fetchData('GetAppointment', {
            value: moment(this.state.k1_date).format('yyyy-MM-DD'),
            type: 2
        }, TimeSlot).then(gData => { 
            //console.log('TimeSlot ---');
             
            if (gData) {
               this.setState({
                timeSlot: gData
               });
               //console.log(this.state.timeSlot);
            }
        });
    }

    allService = () => {
    
        let list = [];
         this.state.services.forEach(xxx => {
         
          list.push(
            <div className="col-lg-4 col-md-6 col-sm-6">
                <div className="service-item mb-4">
                    <div className="icon d-flex align-items-center">
                        <i className={xxx.ava + " text-lg"}></i>
                        <h4 className="mt-3 mb-3"><a href={"service-details/"+xxx.id}> {xxx.name} </a></h4>
                    </div>

                    <div className="content">
                        <p className="mb-4"><a href={"service-details/"+xxx.id}> {xxx.description}</a></p>
                    </div>
                </div>
            </div>
            );
        });

        return list;
    };
    
    allTimeSlot = () => {
    
        let list = [];
        list.push(<option value="-1">Chọn giờ khám</option>);
            this.state.timeSlot.forEach(xxx => {
                list.push(
                    <option value={xxx.id}> {moment(xxx.from).format('HH:mm')}  </option>
                );
        });

        return list;
    };

    allNews = () => {

    let list = [];
        this.state.news.forEach(xxx => {
        
        list.push(
            <div className="col-lg-4 col-md-6 ">
                <div className="department-block mb-5 white_border">
                    <a href={"news-details/"+xxx.id} className="read-more"><img src={xxx.ava} alt="" className="img-fluid w-100"/> </a>
                    <div className="content">
                        <h4 className="mt-4 mb-2 title-color"><a href={"news-details/"+xxx.id} className="read-more">{xxx.name} </a></h4>
                        <p className="mb-4">{xxx.description}</p>
                        <a href={"news-details/"+xxx.id} className="read-more">Xem thêm <i className="icofont-simple-right ml-2"></i></a>
                    </div>
                </div>
            </div>
        );
    });

    return list;
    };

    allFeedback = () => {

        let list = [];
            this.state.feedback.forEach(xxx => {
            
            list.push(
                <div className="testimonial-block style-2 feedback-gray">
                    <div className="testimonial-thumb">
                        <img src={xxx.ava} alt="" className="img-fluid" />
                    </div>

                    <div className="client-info">
                        <h4>{xxx.content}</h4>
                        <span>{xxx.name}</span>
                        <p>
                            {xxx.description}
                        </p>
                    </div>
                    <i className="icofont-quote-right"></i>
                </div>
            );
        });
    
        return list;
        };

    render() {
        return( 
        <AlertContext.Provider value={this.state}>
            <Fragment>
                <Alert /> 
                <section className="banner" style={{backgroundImage: `url(${this.state.imgBanner})`}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 col-md-12 col-xl-7">
                                <div className="block">
                                    <div className="divider mb-3"></div>
                                    <span className="text-uppercase text-sm letter-spacing ">Total Health care solution</span>
                                    <h1 className="mb-3 mt-3">{this.state.sitename}</h1>
                                    
                                    <p className="mb-4 pr-5"> { this.state.section1_desc } </p>
                                    <div className="btn-container ">
     
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/*  Tiêu biểu */}
                <section className="features">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">

                                <div className="feature-block d-lg-flex">
                                    <div className="feature-item mb-5 mb-lg-0">
                                        <div className="feature-icon mb-4 service-flex">
                                            <i className="icofont-doctor-alt"></i>
                                            <div className="service-text">
                                            { this.state.button1_name }
                                            </div>
                                        </div>
                                        <p className="mb-4"> { this.state.button1_desc }</p>
                                        <a href="#appoint" className="btn btn-main-2 btn-round-full">{ this.state.button1_button }</a>
                                    </div>
                                
                                    <div className="feature-item mb-5 mb-lg-0">
                                    <div className="feature-icon mb-4 service-flex">
                                            <i className="icofont-nurse"></i>
                                            <div className="service-text">
                                            { this.state.button2_name }
                                            </div>
                                        </div>
                                        <p className="mb-4"> { this.state.button2_desc } </p>
                                        <a href="#appoint2" className="btn btn-main-2 btn-round-full"> { this.state.button2_button }</a>
                                    </div>
                                
                                    <div className="feature-item mb-5 mb-lg-0">
                                    <div className="feature-icon mb-4 service-flex">
                                            <i className="icofont-pills"></i>
                                            <div className="service-text">
                                            { this.state.button3_name }
                                            </div>
                                        </div>
                                        <p className="mb-4"> { this.state.button3_desc } </p>
                                        <a href="/medicine" className="btn btn-main-2 btn-round-full">{ this.state.button3_button }</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>


                {/*  Dịch vụ */}
                <section className="section service gray-bg" id="service">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-7 text-center">
                                <div className="section-title">
                                    <h2>Dịch vụ tiêu biểu</h2>
                                    <div className="divider mx-auto my-4"></div>
                                    <p> {this.state.service} </p>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            
                            {this.allService()}
                          

                        </div>
                    </div>
                </section>

                {/* Tin tức */}
                <section className="section service-2" id="news">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-7 text-center">
                                <div className="section-title">
                                    <h2>Tin tức</h2>
                                    <div className="divider mx-auto my-4"></div>
                                    <p>{this.state.tintuc}</p>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            
                            {this.allNews()}

                        </div>
                    </div>
                </section>

                {/*  Khám bt */}
                <section className="section appoinment gray-bg" id="appoint">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-6 ">
                                <div className="appoinment-content">
                                    <img src={this.state.imgKham} alt="" className="img-fluid img_radius" />
                                
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-10 ">
                                <div className="appoinment-wrap mt-5 mt-lg-0">
                                    <h2 className="mb-2 title-color">{ this.state.button1_name }</h2>
                                    <p className="mb-4"> {this.state.button1_desc} </p>
                                        <form id="#" className="appoinment-form" method="post" >

                                    <div className="form-group-2 mb-jula">Vui lòng chọn ngày muốn khám: </div>
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <input name="k1_date" 
                                                id="date" value={this.state.k1_date}  
                                                onChange={this.onChangeDate} 
                                                type="date" 
                                                className="form-control" 
                                                placeholder="Chọn ngày khám" />
                                            </div>
                                        </div>

                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <select value={this.state.timeslotSelected} onChange={this.onChangeTimeSlot} className="form-control" id="gioKham">
                                                    {this.allTimeSlot()}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <input name="k1_phone" id="phone" value={this.state.k1_phone}  onChange={this.onChange} onBlur={this.oneChangePhone} type="text" className="form-control" placeholder="Số điện thoại" />
                                            </div>
                                        </div>

                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <input name="k1_name" id="name" value={this.state.k1_name}  onChange={this.onChange} type="text" className="form-control" placeholder="Tên đầy đủ" />
                                            </div>
                                        </div>

                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <input name="k1_bod" id="bod" value={this.state.k1_bod} onChange={this.onChange} type="text" className="form-control"  placeholder="Ngày / Tháng / Năm sinh" />
                                            </div>
                                        </div>

                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <input name="k1_address" id="address" value={this.state.k1_address} onChange={this.onChange} type="text" className="form-control" placeholder="Địa chỉ" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group-2 mb-4">
                                        <textarea name="k1_note" id="note" value={this.state.k1_note} onChange={this.onChange} className="form-control" rows="6" placeholder="Lý do khám bệnh"></textarea>
                                    </div>

                                    <a className="btn white_a btn-main-2  btn-round-full" onClick={this.handleSubmit}>Đặt lịch khám <i className="icofont-simple-right ml-2  "></i></a>
                                </form>
                            </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* Siêu âm */}
                <section className="section appoinment" id="appoint2">
                    <div className="container">
                        <div className="row align-items-center">
                            
                            <div className="col-lg-6 col-md-10 ">
                                <div className="appoinment-wrap mt-5 mt-lg-0">
                                    <h2 className="mb-2 title-color">{ this.state.button2_name }</h2>
                                    <p className="mb-4"> {this.state.button2_desc} </p>
                                    {/* <form id="#" className="appoinment-form2" method="post" >
                                    <div className="row">
                                       

                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <input name="k1_date" id="date" value={this.state.k1_date}  onChange={this.onChange} type="date" className="form-control" placeholder="Ngày khám" />
                                            </div>
                                        </div>

                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <input name="k1_time" id="time"  onChange={this.onChange} type="text" className="form-control" placeholder="Giờ khám" />
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <input name="k1_phone" id="phone" value={this.state.k1_phone} onChange={this.onChange} type="text" className="form-control" placeholder="Số điện thoại" />
                                            </div>
                                        </div>

                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <input name="k1_name" id="name" value={this.state.k1_name} onChange={this.onChange} type="text" className="form-control" placeholder="Tên đầy đủ" />
                                            </div>
                                        </div>

                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <input name="k1_bod" id="bod" value={this.state.k1_bod} onChange={this.onChange} type="text" className="form-control"  placeholder="Ngày / Tháng / Năm sinh" />
                                            </div>
                                        </div>

                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <input name="k1_address" id="address" value={this.state.k1_address} onChange={this.onChange} type="text" className="form-control" placeholder="Địa chỉ" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group-2 mb-4">
                                        <textarea name="k1_note" id="note" value={this.state.k1_note} onChange={this.onChange} className="form-control" rows="6" placeholder="Lý do khám bệnh"></textarea>
                                    </div>

                                    <a className="btn white_a btn-main btn-round-full" onClick={this.handleSubmitSA}>Đặt lịch siêu âm <i className="icofont-simple-right ml-2  "></i></a>
                                </form> */}
                            </div>
                            </div> 

                            <div className="col-lg-6 ">
                                <div >
                                    <img src={this.state.imgSieuAm} alt="" className="img-fluid img_radius" />
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/*  Xem lại giờ hẹn */}
                <section className="section appoinment gray-bg" id="check">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-6 ">
                                <div className="appoinment-content">
                                    <img src={this.state.imgXemlai} alt="" className="img-fluid" />
                                 
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-10 ">
                                <div className="appoinment-wrap mt-5 mt-lg-0">
                                    <h2 className="mb-2 title-color">Xem lại lịch hẹn</h2>
                                    <p className="mb-4"> {this.state.xemlai} </p>
                                        <form id="#" className="appoinment-form" method="post" >
                                    <div className="row">
                                       

                                      
                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <input name="k3_phone" id="phone3" value={this.state.k3_phone} onChange={this.onChange} type="text" className="form-control" placeholder="Số điện thoại" />
                                            </div>
                                        </div>

                                       
                                    </div>

                                    <a className="btn white_a btn-main-2  btn-round-full" onClick={this.handleSubmitAgain}> Xem lại lịch hẹn <i className="icofont-simple-right ml-2  "></i></a>
                                </form>
                            </div>
                            </div>
                        </div>
                    </div>
                </section>


                <section className="section testimonial-2">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-7">
                                <div className="section-title text-center">
                                    <h2>Ý kiến khách hàng</h2>
                                    <div className="divider mx-auto my-4"></div>
                                    <p> { this.state.ykien } </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-12 testimonial-wrap-2">

                                {this.allFeedback()}
                                
                            </div>
                        </div>
                    </div>
                </section>
           
            </Fragment>
        </AlertContext.Provider>
        )
    }

}

export default Home;
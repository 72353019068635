import React, { Component } from 'react';
import { Fragment } from 'react';
import {DataRPC, MedicineClass, ImageText} from '../../rpc-client/DataRPC';
import ReactHtmlParser from "react-html-parser";

class MedicineDetails extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            news: [],
            medicine_order_name: "",
            medicine_order_button: "",

            name: "",
            address: "",
            phone: "",
            quantity: "",
            med_id: ""
        }
    }

    async componentDidMount() {
        // Get Medicine
        let id = this.props.match.params.id;
        this.setState({
            med_id: id
       });
        new DataRPC().fetchData('GetMedicine', {
            type: 1,
            id: id
        }, MedicineClass).then(gData => { 
            
            if (gData) {
               this.setState({
                    news: gData
               });
               console.log(this.state.news);
            }
        });

        // ---------------------------

        new DataRPC().fetchData('GetImageText', {
            page: "medicine_order"
        }, ImageText).then(gData => { 
            if (gData) {
                this.setState({medicine_order_name: gData.find(x => x.komoku === "medicine_order_name").content});
                this.setState({medicine_order_button: gData.find(x => x.komoku === "medicine_order_button").content});
            }
        });
    }

    handleSubmit = async (e) => {
        e.preventDefault();

        if (
            !this.state.name ||
            !this.state.address ||
            !this.state.quantity ||
            !this.state.phone) {
            alert('Bạn chưa điền đầy đủ thông tin đặt hàng');
            return;
        }

        if (window.confirm("Xác nhận đặt thuốc ?")) {
            new DataRPC().fetchRawData('MedicineOrder', {
                phone: this.state.phone,
                address: this.state.address,
                quantity: this.state.quantity,
                name: this.state.name,
                med_id: this.state.med_id,
            }).then(gData => { 
                if (gData) {
                    console.log(gData);
                    alert('Đặt thuốc thành công, chúng tôi sẽ liên lạc với bạn trong thời gian sớm nhất');
                    this.setState({
                        name: "",
                        address: "",
                        phone: "",
                        quantity: ""
                    })
                }
                else {
                    alert('Có lỗi xảy ra, xin thử lại sau');
                }
            });
        }
    }

    onChange = (e) => {
        this.setState({[e.target.name]:e.target.value});
    } 

    render() {
        return( 
            <Fragment>
                <section className="page-title bg-1">
                    <div className="overlay"></div>
                    <div className="container">
                        <div className="row">
                        <div className="col-md-12">
                            <div className="block text-center">
                            <span className="text-white">{this.state.news.description}</span>
                            <h1 className="text-capitalize mb-5 text-lg">{this.state.news.name}</h1>
                            </div>
                        </div>
                        </div>
                    </div>
                    </section>


                    <section className="section department-single gray-bg">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="department-img">
                                        <img src={this.state.news.ava} alt="" className="img-fluid"/>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-lg-8">
                                    <div className="department-content mt-5">
                                        <h3 className="text-md">{this.state.news.name}</h3>
                                        <div className="divider my-4"></div>
                                        
                                        {ReactHtmlParser(this.state.news.content)}

                                        
                                    </div>
                                </div>

                         
                            </div>
                        </div>
                    </section>
            </Fragment>
        )
    }
}

export default MedicineDetails;
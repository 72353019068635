import React from 'react';
import {ImageText, DataRPC} from '../../rpc-client/DataRPC';

class Header extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            address: "",
            sdt: "",
            email: "",
            menu1: "",
            menu2: "",
            menu3: "",
            menu4: "",
            menu5: "",
            nav_image: ""
        }
    }

    async componentDidMount() {
        new DataRPC().fetchData('GetImageText', {
            page: "nav"
        }, ImageText).then(gData => { 
            if (gData) {
                console.log(gData.find(x => x.komoku === "Address"));
                this.setState({address: gData.find(x => x.komoku === "Address").content});
                this.setState({sdt: gData.find(x => x.komoku === "SDT").content});
                this.setState({email: gData.find(x => x.komoku === "Email").content});

                this.setState({menu1: gData.find(x => x.komoku === "menu1").content});
                this.setState({menu2: gData.find(x => x.komoku === "menu2").content});
                this.setState({menu3: gData.find(x => x.komoku === "menu3").content});
                this.setState({menu4: gData.find(x => x.komoku === "menu4").content});
                this.setState({menu5: gData.find(x => x.komoku === "menu5").content});

                this.setState({nav_image: gData.find(x => x.komoku === "nav_image").content});
            }
        });
    }

    render() {
        return(
            <header>
                <div className="header-top-bar">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-6">
                                <ul className="top-bar-info list-inline-item pl-0 mb-0">
                                    <li className="list-inline-item"><a href="mailto:support@gmail.com"><i className="icofont-support-faq mr-2"></i>{ this.state.email }</a></li>
                                    <li className="list-inline-item"><i className="icofont-location-pin mr-2"></i> { this.state.address } </li>
                                </ul>
                            </div>
                            <div className="col-lg-6">
                                <div className="text-lg-right top-right-bar mt-2 mt-lg-0">
                                    <a href="tel:+23-345-67890" >
                                        <span>SĐT Liên hệ : </span>
                                        <span className="h4">{ this.state.sdt }</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <nav className="navbar navbar-expand-lg navigation" id="navbar">
                    <div className="container">
                        <a className="navbar-brand" href="index.html">
                            <img src={this.state.nav_image} alt="" className="img-fluid img-logo" />
                        </a>

                        <button className="navbar-toggler collapsed" type="button" data-toggle="collapse" data-target="#navbarmain" aria-controls="navbarmain" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="icofont-navigation-menu service-text"> Menu </span>
                    </button>
                
                    <div className="collapse navbar-collapse" id="navbarmain">
                        <ul className="navbar-nav ml-auto">
                        <li className="nav-item active">
                            <a className="nav-link" href="/">{this.state.menu1}</a>
                        </li>
                        <li className="nav-item"><a className="nav-link" href="/#check">{this.state.menu2}</a></li>
                            <li className="nav-item"><a className="nav-link" href="/#service">{this.state.menu3}</a></li>
                            <li className="nav-item"><a className="nav-link" href="/#news">{this.state.menu4}</a></li>
                            <li className="nav-item"><a className="nav-link" href="/medicine">{this.state.menu5}</a></li>
                            {/* <li className="nav-item">
                                <Link to="/#news"  
                                    smooth={true}
                                    offset={-70}
                                    duration={500}>
                                    {this.state.menu5}
                                </Link>
                            </li> */}
                        </ul>
                    </div>
                    </div>
                </nav>
            </header>
        );
    }
}

export default Header;
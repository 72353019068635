import React from 'react';
import { Fragment } from 'react';
import {DataRPC, News, ImageText} from '../../rpc-client/DataRPC';
import ReactHtmlParser from "react-html-parser";

class Details extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            content: "",
            name: "",
            create_date: "",
            description: "",
            ava: "",
            
            news_title: "",
            news_desc: "",
            news_image: ""
        }
    }

    async componentDidMount() {

        let id = this.props.match.params.id;

        new DataRPC().fetchData('GetNews', {
            id: id,
            type: 1
        }, News).then(gData => { 
            if (gData) {
                console.log(gData);

                this.setState({
                    content: gData.content,
                    name: gData.name,
                    create_date: gData.create_date,
                    description: gData.description,
                    ava: gData.ava
                });
            }
        });

        new DataRPC().fetchData('GetImageText', {
            page: "news"
        }, ImageText).then(gData => { 
            if (gData) {
                this.setState({news_title: gData.find(x => x.komoku === "news_title").content});
                this.setState({news_desc: gData.find(x => x.komoku === "news_desc").content});
                this.setState({news_image: gData.find(x => x.komoku === "news_image").content});
            }
        });
    }

    render() {
        return( 
            <Fragment>

                <section className="page-title bg-1" style={{backgroundImage: `url(${this.state.news_image})`}}>
                <div className="overlay"></div>
                <div className="container">
                    <div className="row">
                    <div className="col-md-12">
                        <div className="block text-center">
                        <span className="text-white">{this.state.news_title}</span>
                        <h1 className="text-capitalize mb-5 text-lg">{this.state.news_desc}</h1>
                        </div>
                    </div>
                    </div>
                </div>
                </section>

                <section className="section blog-wrap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8">
                                <div className="row">
                                    <div className="col-lg-12 mb-5">
                                        <div className="single-blog-item">
                                            <img src={this.state.ava} alt="" className="img-fluid"/>

                                            <div className="blog-item-content mt-5">
                                                <div className="blog-item-meta mb-3">
                                                    <span className="text-color-2 text-capitalize mr-3"><i className="icofont-book-mark mr-2"></i> Tin tức</span>
                                                    <span className="text-black text-capitalize mr-3"><i className="icofont-calendar mr-2"></i> {this.state.create_date}</span>
                                                </div> 

                                                <h2 className="mb-4 text-md">{this.state.name}</h2>

                                                <p className="lead mb-4">{this.state.description}</p>

                                                {/* {this.state.content} */}
                                                {ReactHtmlParser(this.state.content)}
                            
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </Fragment>
        )
    }

}

export default Details;